.card-container {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.card-container div {
  padding: 16px 0;
}

.card-container img {
  max-width: 393px;
  height: auto;
}

.card-person-name {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  letter-spacing: -0.019em !important;
  color: #282c34 !important;
  margin-bottom: 10px !important;
}

.download-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  color: #94ba2c;
  border: none;
  background: none;
}

.about {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em !important;
  color: #687076 !important;
}

@media screen and (max-width: 768px) {
  .card-container {
    width: 100%;
  }
}
