.chart-container {
  width: 20%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eceef0;
  background: rgba(255, 255, 255, 0.92);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 264px;
}

canvas {
  max-width: 200px !important;
  max-height: 200px !important;
}
