.toolbar-quick-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.toolbar-quick-filter input {
  width: 350px;
  height: 10px;
}

.toolbar-quick-filter fieldset {
  border-radius: 6px;
  border: 1px solid #d7dbdf;
}

.active-filter svg path {
  fill: #8da4ef !important;
}

.active-selected-row svg path {
  fill: #7e868c !important;
}

.active-selected-row {
  border: 1px solid var(--light-slate-10, #7e868c) !important;
  background: var(--light-slate-7, #d7dbdf) !important;
}

.active-selected-row .selected-row-count {
  background-color: #7e868c;
  color: #fff;
  min-width: 30px;
  padding: 0 5px;
  max-width: max-content;
  height: 20px;
  border-radius: 20px;
  margin-left: 5px;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.search-filter-bar div label {
  margin: -5px 0;
}
