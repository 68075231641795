.navbar {
  display: flex;
  width: 90vw;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 80px;
  height: 106px;
}

.navbar-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 640px;
  height: 54px;
}

.navbar-logo svg {
  cursor: pointer;
}

.navbar .navbar-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px;
  gap: 13px;
  flex: none;
  align-self: stretch;
}

.navbar .navbar-items button {
  border-radius: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  letter-spacing: -0.011em;
}

.navbar .navbar-items button:last-child {
  margin-right: 0;
}

.login-btn {
  background-color: #153226 !important;
  border-radius: 6px !important;
  color: #fbfcfd !important;
  padding: 0 16px !important;
}

@media screen and (max-width: 768px) {
  .navbar-logo {
    height: 40px;
  }
}
