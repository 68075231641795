.page-wrapper-container .main-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;
  max-width: 50%;
  height: 216px;
}

.page-wrapper-container .tag-line {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.022em;
  color: #fbfcfd;
  flex-grow: 1;
}

.page-wrapper-container .tag-line2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: -0.022em;
  color: #fbfcfd;
  padding: 5px;
}

.page-wrapper-container .subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.017em;
  color: #fbfcfd;
  margin-top: 10px;
  padding: 5px;
}

/* .page-wrapper-container .sub-header {
  margin-top: 4%;
} */

.page-wrapper-container .btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 32px;
  height: 48px;
}

.page-wrapper-container .btn-container button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  height: 48px;
  background: rgba(0, 0, 0, 0.22);
  border: 1px solid #99d52a;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #fbfcfd;
}

@media screen and (max-width: 768px) {
  .page-wrapper-container .main-body {
    max-width: 90%;
  }

  .page-wrapper-container .tag-line {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  .page-wrapper-container .btn-container {
    flex-direction: column;
    margin-top: 50px;
  }
  .page-wrapper-container .btn-container button {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .page-wrapper-container .tag-line2 {
    font-size: 24px !important;
    line-height: 20px !important;
  }
  .page-wrapper-container .subtitle {
    font-size: 18px;
    line-height: 20px;
  }
}
