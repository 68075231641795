.status-tooltip-container {
  background-color: #282c34;
  color: #fff;
  max-width: 255px;
  border-radius: 6px;
  padding: 10px;
}

.status-tooltip-container tbody {
  padding: 10px !important;
}

.status-tooltip-container tbody p {
  color: var(--light-black-1, #f9fafa);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 0px;
}

.status-tooltip-container tbody td {
  color: var(--light-black-1, #f9fafa);
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid #faf9f834 !important;
}

.status-tooltip-container tbody tr:last-child td {
  border-bottom: none !important;
}
