.dashboard-navbar-container {
  position: fixed;
  background-color: #fff;
  z-index: 2;
  margin-top: 60px;
}

.dashboard-navbar-container.highlighted-component {
  position: fixed;
}

.item-nav-bar-tab {
  display: flex;
  align-items: center;
}

.item-nav-bar-tab span {
  margin-left: 10px;
  font-size: 18px;
}

.download-btn-box {
  width: 220px !important;
  border: 1px solid #d7dbdf !important;
  color: #687076 !important;
  font-size: 14px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: -0.084px !important;
  height: 32px !important;
}

.download-btn-box p {
  margin-left: 10px;
}

.disabled-btn-box {
  border: 1px solid #d7dbdf !important;
  color: #687076 !important;
  width: 220px;
  height: 32px;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.loading-btn-box svg path {
  fill: #7e868c !important;
}

.disabled-btn-box p {
  margin-left: 10px;
}
