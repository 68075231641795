.tour-guide-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.tour-guide {
  position: absolute;
  background-color: #1c1f25;
  color: #ffffff;
  padding: 24px;
  border-radius: 4px;
  width: 20%;
  width: 300px;
  z-index: 1300;
}

.close-tour-dialog {
  color: #abb0bb !important;
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
}

.step-title {
  color: #f9fafa;
  font-size: 16px !important;
  font-family: Inter !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: -0.176px;
}

.step-desc {
  color: var(--light-black-1, #f9fafa);
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: -0.176px;
  white-space: pre-line;
}

.tour-guide-arrow {
  display: block;
  position: absolute;
  border-left: solid 10px #1c1f25;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;
  z-index: 2;
}

.footer-divider {
  background-color: #f9fafa;
  line-height: 10px;
  margin: 10px 0 !important;
}

.tour-guide-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-count {
  color: #f9fafa;
  font-size: 12px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.footer-btn {
  color: #f9fafa !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-decoration-line: underline !important;
}

.highlighted-component {
  position: relative;
  z-index: 1201 !important;
  background-color: #f9fafa;
  border-radius: 10px;
  padding: 10px;
}

/* Media query for screens where the dialog should be aligned to the top */
@media (max-width: 768px) {
  .tour-guide.is-dashboard {
    bottom: auto;
    top: -10px; /* Adjust this value as needed */
  }
  .tour-guide-arrow.is-dashboard {
    bottom: auto;
    top: -20px; /* Position arrow at the top */
  }

  /* Adjust the top position for other steps */
  .tour-guide.is-tabs {
    bottom: auto;
    top: 0; /* Adjust this value as needed */
  }

  .tour-guide-arrow.is-tabs {
    bottom: auto;
    top: -20px; /* Position arrow at the top */
  }

  /* Similarly adjust top position for other steps as needed */
}
