.sidebar-logo {
  margin: 20px;
}

.sidebar-list-item {
  width: 90% !important;
  margin: 0 auto;
}

.sidebar-list-item .css-cveggr-MuiListItemIcon-root,
.css-1f8bwsm {
  min-width: 35px !important;
}

.active-tab {
  background-color: #3e63dd !important;
  border-radius: 6px;
  color: #fff;
}

.active-tab svg path {
  fill: #fff;
}

.active-tab svg circle,
line {
  stroke: #fff;
}

.sidebar-list span {
  font-size: 16px;
  color: #687076;
}

.sidebar-dashboard span {
  font-size: 16px !important;
  color: #fff !important;
}

.sidebar-title span {
  font-size: 12px;
  color: #7e868c;
}

.download-icon path {
  fill: #3e63dd;
}

.navbar-profile-container button {
  cursor: pointer;
  border-radius: 6px;
  padding: 5px;
  color: #687076;
}

.navbar-profile-container div div {
  margin: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.navbar-profile-container div {
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.custom-toolbar {
  min-height: 37px !important;
}

.no-scroll {
  overflow: hidden !important ;
}
