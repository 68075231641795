.main-container {
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-content-box {
  max-width: 90vw;
  align-self: center;
}

.about-content {
  display: flex;
  margin: 50px 0;
  padding: 16px 24px;
  justify-content: space-between;
}

.section-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  max-width: 328px;
  min-width: min-content;
  padding: 5px;
  margin: 5px;
  overflow: auto;
  /* height: 150px; */
}

.section-left p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.022em;
}

.section-right {
  max-width: 864px;
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.about-section-container {
  width: 100%;
}

.about-section-description {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  color: #687076;
  letter-spacing: -0.014em;
}

.about-section-sub-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  max-width: 263px;
}

.about-section-sub-box-2 {
  margin-left: 19.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  max-width: 263px;
}

.about-section-description-title {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.017em;
  color: #282c34;
}

.about-section-description-body {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em;
  color: #687076;
}

.section2-right {
  max-width: 864px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  gap: 64px;
  flex-wrap: wrap;
}

.faq-main-box {
  max-width: 840px;
  padding: 10px;
  align-self: center;
  margin-top: 70px;
}

.faq-content-title {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.017em;
  color: #282c34;
}

.faq-content-body {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em;
  color: #687076;
  margin: 16px 0 !important;
}

.contact-container {
  align-self: center;
  padding: 20px;
  width: 1200px;
  margin: 0 auto;
}

.contact-container .contact-box {
  display: flex;
  justify-content: space-between;
  margin: 80px 0;
}

.section-right-title {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 30px !important;
  letter-spacing: -0.017em;
  color: #282c34;
}

.section-right-body {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em;
  color: #687076;
}

.section-right-body-2 {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: -0.011em;
  color: #687076;
  text-decoration: none;
}

.search-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 16px;
  width: 60vw;
  background: #f9fafa;
  border: 1px solid #c1c8cd;
  border-radius: 16px;
  margin: auto 0;
}

.search-box .location {
  width: 25%;
  margin-left: -10px;
}

.search-box .community {
  width: 70%;
  margin-left: -10px;
}

.search-box .search-button {
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 85px;
  height: 48px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
}

.search-box-mobile {
  display: none;
  box-sizing: border-box;
  padding: 8px;
  gap: 16px;
  width: 60vw;
  background: #f9fafa;
  border: 1px solid #c1c8cd;
  border-radius: 16px;
}

.search-button-mobile {
  padding: 12px 16px;
  gap: 8px;
  width: 85px;
  height: 48px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
}

.markets-data-grid-box {
  width: 90%;
  overflow: auto;
  margin: 60px auto;
  min-height: 40vh;
}

.markets-data-grid {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  display: flex;
  color: #111318;
  height: 10px;
}

.empty-search-results {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
}

.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 73vh;
}

.login-left-section {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-right-section {
  background: #282c34;
}

.login-header {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 40px !important;
  line-height: 50px !important;
  letter-spacing: -0.022em;
  color: #687076;
}
.login-fpwd {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-right-sub-box {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

@media screen and (max-width: 1200px) {
  .contact-container {
    width: 90vw;
  }
}

@media screen and (max-width: 768px) {
  .search-box {
    display: none;
    overflow: auto;
  }
  .search-box .location {
    width: 25%;
    margin-left: -10px;
  }
  .search-box .community {
    width: 70%;
    margin-left: -10px;
  }
  .search-button {
    max-width: 20px;
  }
  .search-box-mobile {
    display: block;
    width: 90vw !important;
  }
  .about-content {
    margin: 10px 0 0 0;
    padding: 8px 12px;
    flex-direction: column;
  }
  .section-left {
    max-width: 100%;
    padding: 0;
    margin: auto;
    margin-bottom: 30px;
  }
  .section-left p {
    text-align: center;
  }
  .section-right {
    max-width: 100%;
  }
  .section-right-sub-box {
    flex-direction: column;
  }
  .about-section-sub-box {
    padding: 10px 0;
    gap: 8px;
    max-width: 100%;
  }
  .about-section-sub-box-2 {
    margin: 0;
    padding: 10px 0;
    gap: 8px;
    max-width: 100%;
  }
  .section2-right {
    max-width: 100%;
    flex-direction: column;
    padding: 5px;
    margin: 6px;
    gap: 32px;
  }
  .faq-main-box {
    max-width: 90%;
    padding: 20px 10px;
    margin-top: 10px;
  }
  .contact-container .contact-box {
    flex-direction: column;
    margin: 40px 0;
  }
  .markets-data-grid-box {
    width: 90%;
    overflow: auto;
    margin: 10px auto;
  }

  .markets-data-grid {
    width: 100%;
  }
}
