.container {
  display: flex;
  justify-content: center;
}

.container div {
  width: 90vw;
  @media (max-width: 767px) {
    width: 100vw;
  }
}

.container div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.container p {
  color: var(--light-slate-11, #687076);
  text-align: right;
  /* text-xs · 12/regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
