.custom-dialog-overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-dialog {
  background-color: #ffffff;
  width: 100%;
  padding: 0 20px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-dialog-header {
  position: relative;
}

.custom-dialog-title {
  color: #7e868c;
  font-size: 24px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: -0.456px;
}

.custom-dialog-content {
  color: #889096;
  padding: 0 24px 20px 24px !important;
}

.custom-dialog-content p {
  font-size: 18px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: -0.252px;
}

.custom-dialog-description {
  font-size: 14px;
}

.custom-dialog-button {
  margin-top: 20px;
}

.dashboard-analytics-title {
  color: #7e868c;
  font-size: 24px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: -0.456px;
  margin: 10px 0 !important;
}

.dashboard-analytics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.table {
  border-radius: 8px;
  background-color: #e6edfe;
  border-collapse: separate !important;
  border-spacing: 0;
  max-width: max-content;
  padding: 8px;
  max-height: 264px;
}

.table thead th {
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  color: #153226;
  border-bottom: 1px solid #8da4ef;
}

.table tbody td {
  border-bottom: 1px solid #c6d4f9;
  font-size: 14px;
  padding: 10px;
  color: #3451b2;
}

.table tbody tr:last-child td {
  border-bottom: none;
}

.table-highlighted-row {
  background-color: #8da4ef;
}

.table-highlighted-row td {
  color: #fff !important;
}

.chart-overview {
  border: 1px solid #eceef0;
  border-radius: 8px;
  border-collapse: separate !important;
  border-spacing: 0;
  max-width: 25%;
  padding: 8px;
  height: 264px;
}

.chart-overview thead th {
  font-size: 12px !important;
  font-family: Inter !important;
  line-height: 18px !important;
  color: #687076 !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #000;
}

.chart-overview tbody td {
  font-size: 12px !important;
  font-family: Inter !important;
  line-height: 18px !important;
  color: #687076 !important;
  padding: 10px;
}

.chart-overview tbody tr:last-child td {
  border-bottom: none;
}

.color-overview-box {
  display: flex !important;
  align-items: center !important;
}

.color-box {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.color-overview-highlighted-row {
  background-color: #889096;
}

.color-overview-highlighted-row .color-overview-box {
  color: #fff !important;
}

.dashboard-datagrid {
  border: none !important;
}

.dashboard-datagrid .MuiDataGrid-main {
  border: 1px solid #d7dbdf;
  border-radius: 6px;
}

.dashboard-table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
}

.dashboard-table-actions button {
  border-radius: 4px;
  border: 1px solid #d7dbdf;
  background: rgba(255, 255, 255, 0.92);
  color: #687076;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  width: 220px;
  height: 32px;
}

.dashboard-table-actions button p {
  margin-left: 10px;
}

.dashboard-page-title {
  color: #687076;
  font-size: 24px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: -0.456px;
}
